import logo from './logo.svg';
import './App.css';
import Login from './pages/login';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import AdminPanel from './pages/adminPanel';
import KommendeKoncerter from './pages/adminPages/kommendeKoncerter';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/adminPanel/*" element={<AdminRoutes />} />
        <Route path="/*" element={<div style={{textAlign: 'center'}}><h1>404</h1></div>} />
      </Routes>
      </Router>
  );
}

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminPanel />} />
      <Route path="/kommendeKoncerter" element={<KommendeKoncerter />} />
    </Routes>
  );
};

export default App;
