import axios from 'axios';

const checkUser = async () => {
    try {
        const res = await axios.get('https://www.api.kilofon.dk/api/verifyAuth',
            { withCredentials: true });
        console.log(res.data);
        return res.data.isAuth;
    } catch (error) {
        console.log(error);
    }
}

export default checkUser;


