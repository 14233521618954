import React, { useState, useEffect, useCallback } from 'react';
import { Drawer, List, ListItem, ListItemText, AppBar, Button, Toolbar, IconButton, Typography, useTheme, useMediaQuery, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import axios from 'axios';

const drawerWidth = 240;

const LeftSidePanel = ({ mobileOpen, handleDrawerToggle }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    //create a callback to logout
    
    const handleLogout = useCallback(async () => {
        try {
            await axios.get('https://www.api.kilofon.dk/api/logout', { withCredentials: true })
            .then((response) => {
                window.location.href = '/';
                }
            );

        } catch (error) {
            console.error('There was an error logging out!', error);
        }
    }, []);


    const drawerContent = (
        <List sx={{ 
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: '#404840',
            color: 'white',
        }}>
            <ListItem button  component={Link} to="/adminPanel">
                <ListItemText primary="Admin Panel" />
            </ListItem>
            <ListItem button component={Link} to="/adminPanel/kommendeKoncerter">
                <ListItemText primary="Kommende koncerter" />
            </ListItem>
        <Button variant="contained" sx={{ width: "5rem", margin: "1rem" }}
        color="error" onClick={handleLogout}>
        Logout
        </Button>
        </List>
    );

    return (
        <>
            {isMobile ? (
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawerContent}
                </Drawer>
            ) : (
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
                    }}
                >
                    {drawerContent}
                </Drawer>
            )}
        </>
    );
};

export default LeftSidePanel;

