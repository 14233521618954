import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import axios from 'axios';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

function TrafficStatistics() {
    const [rawTrafficData, setRawTrafficData] = useState([]);
    const [currentInterval, setCurrentInterval] = useState('day');
    const [trafficData, setTrafficData] = useState({
        roden: [],
        halbergfriends: [],
        santacruz: [],
        powertrio: []
    });
    const [length, setLength] = useState(0);

    useEffect(() => {
        const fetchTrafficData = async () => {
            try {
                const response = await axios.get('https://www.api.kilofon.dk/api/pageTrafic', { withCredentials: true });
                setRawTrafficData(response.data.pageTrafic);
                setTrafficData(calculateTraffic(response.data.pageTrafic, 'day'));
            } catch (error) {
                console.error('There was an error fetching the traffic!', error);
            }
        };
        fetchTrafficData();
    }, []);

    const calculateTraffic = (data, interval) => {
        const now = new Date();
        const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const timeIntervals = {
            day: startOfDay,
            week: 604800000,
            month: 2628000000,
            year: 31540000000
        };

        let filteredData;
        if (interval === 'day') {
            filteredData = data.filter(page => {
                const pageDate = new Date(page.timeStamps);
                return pageDate >= startOfDay;
            });
        } else {
            filteredData = data.filter(page => {
                const pageDate = new Date(page.timeStamps);
                const timeDifference = now - pageDate;
                return timeDifference <= timeIntervals[interval];
            });
        }

        const intervalsLength = interval === 'day' ? 24 : interval === 'week' ? 7 : interval === 'month' ? new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate() : 12;

        const countin = {
            roden: Array(intervalsLength).fill(0),
            halbergfriends: Array(intervalsLength).fill(0),
            santacruz: Array(intervalsLength).fill(0),
            powertrio: Array(intervalsLength).fill(0)
        };

        filteredData.forEach(page => {
            const date = new Date(page.timeStamps);
            let index;
            if (interval === 'day') {
                index = date.getHours();
            } else if (interval === 'week') {
                index = date.getDay(); // Sunday is 0, Saturday is 6
            } else if (interval === 'month') {
                index = date.getDate() - 1;
            } else if (interval === 'year') {
                index = date.getMonth();
            }

            if (page.url === '/') {
                countin.roden[index]++;
            } else if (page.url === '/halbergfriends') {
                countin.halbergfriends[index]++;
            } else if (page.url === '/santacruz') {
                countin.santacruz[index]++;
            } else if (page.url === '/powertrio') {
                countin.powertrio[index]++;
            }
        });

        setLength(filteredData.length);
        return countin;
    };

    const handleIntervalChange = (interval) => {
        setCurrentInterval(interval);
        setTrafficData(calculateTraffic(rawTrafficData, interval));
    };

    const getLabels = () => {
        if (currentInterval === 'day') return Array.from({ length: 24 }, (_, i) => `${i}:00`);
        if (currentInterval === 'week') return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        if (currentInterval === 'month') return Array.from({ length: new Date().getDate() }, (_, i) => `Day ${i + 1}`);
        if (currentInterval === 'year') return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    };

    const data = {
        labels: getLabels(),
        datasets: [
            {
                label: 'Roden',
                data: trafficData.roden,
                backgroundColor: '#2E8B57',
            },
            {
                label: 'Halbergfriends',
                data: trafficData.halbergfriends,
                backgroundColor: '#36A2EB',
            },
            {
                label: 'Santacruz',
                data: trafficData.santacruz,
                backgroundColor: '#FFCE56',
            },
            {
                label: 'Powertrio',
                data: trafficData.powertrio,
                backgroundColor: '#FF6347',
            }
        ]
    };

    const options = {
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    return (
        <Box bgcolor="paleturquoise" p={2} borderRadius={2} alignItems="center" justifyContent="center" display="flex" flexDirection="column" sx={{ minHeight: 650 }} textAlign="center">
            <Typography variant="h4" gutterBottom>
                Statistik
            </Typography>
            <Box sx={{ width: 600, minHeight: 600, padding: 2 }}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Traffic Statistics over one {currentInterval}
                </Typography>
                <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>
                    Pebel has been visited {length} times in the last {currentInterval}.
                </Typography>
                <Bar data={data} options={options} />
                <Box sx={{ marginTop: 2 }}>
                    <Button variant="contained" onClick={() => handleIntervalChange('day')} sx={{
                        backgroundColor: currentInterval === 'day' ? 'green' : 'default',
                        '&:hover': {
                            backgroundColor: currentInterval === 'day' ? 'green' : 'default',
                        }
                    }}>Day</Button>
                    <Button variant="contained" onClick={() => handleIntervalChange('week')} sx={{
                        marginLeft: 1,
                        backgroundColor: currentInterval === 'week' ? 'green' : 'default',
                        '&:hover': {
                            backgroundColor: currentInterval === 'week' ? 'green' : 'default',
                        }
                    }}>Week</Button>
                    <Button variant="contained" onClick={() => handleIntervalChange('month')} sx={{
                        marginLeft: 1,
                        backgroundColor: currentInterval === 'month' ? 'green' : 'default',
                        '&:hover': {
                            backgroundColor: currentInterval === 'month' ? 'green' : 'default',
                        }
                    }}>Month</Button>
                    <Button variant="contained" onClick={() => handleIntervalChange('year')} sx={{
                        marginLeft: 1,
                        backgroundColor: currentInterval === 'year' ? 'green' : 'default',
                        '&:hover': {
                            backgroundColor: currentInterval === 'year' ? 'green' : 'default',
                        }
                    }}>Year</Button>
                </Box>
            </Box>
        </Box>
    );
}

export default TrafficStatistics;

