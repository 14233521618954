import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import displayLoading from './logding';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAuth = async () => {
      try {
        const res = await axios.get('https://www.api.kilofon.dk/api/verifyAuth', { withCredentials: true });
        if (res.data.isAuth) {
          navigate('/adminPanel');
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };
    verifyAuth();
  }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        //use axios to send data to the server use withCredentials: true
        try {
            axios.post('https://www.api.kilofon.dk/api/login', {
                email: email,
                password: password
            }, { withCredentials: true }).then((res) => {
                console.log(res.data);
                if (res.status === 200) {
                    navigate('/adminPanel');
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    if (loading) {
        return displayLoading();
    }

    return (
     <Container component="main" maxWidth="xs">
      <Box
        sx={{
        textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '90vh',
        }}
      >
        <Box sx={{ position: 'absolute', top: 0, left: 0,
                alignItems: 'center', justifyContent: 'center',
                display: 'flex',
                bgcolor:'#667365', width: '100%', textAlign: 'center',
                height: '100%', zIndex: -1 }}>

        <Box sx={{ position: 'absolute',
                borderRadius: '50%',
                transform: 'translate(-150%, -120%)',
                backgroundImage: {
                    md: 'url(https://poulhalberg.dk/____impro/1/onewebmedia/DSC_8350.jpg?etag=%22af5bf1-6250541d%22&sourceContentType=image%2Fjpeg&ignoreAspectRatio&resize=2500,3750&quality=20)',
                    xs: 'none',
                },
                backgroundSize: 'cover',
                height: '200px',
                width: '200px',
                  }} />


        <Box sx={{ position: 'absolute',
                zIndex: -1,
                bgcolor: { xs: 'transparent', md: 'black' },
                borderRadius: '50%',
                transform: 'translate(-140%, -110%)',
                height: '200px',
                width: '200px',
        }} />
        </Box>
        
        <Box 
        sx={{
            width: '100%',
            maxWidth: '400px',
            p: 3,
            border: '1px solid #ccc',
            borderRadius: 2,
            bgcolor: 'background.paper',
            boxShadow: 24,
            m: 4,
            }} >
        <Typography variant="h4">Login admin</Typography>
        <form onSubmit={handleSubmit}>
        <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 3 }}>
            Login
        </Button>
        </form>
        </Box>
        </Box>
        </Container>

    );
}

export default Login;
