import React, { useState, useEffect } from 'react';
import { Container, Box, TextField, Button, Typography, List, ListItem, ListItemText, Link, MenuItem, Select, FormControl, InputLabel, Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';
import LeftSidePanel from '../leftSidePalen';
import axios from 'axios';
import checkUser from '../checkUser';
import { useLocation } from 'react-router-dom';
import displayLoading from '../logding';

function KommendeKoncerter() {
    // Get the concert to edit from the location state
    const location = useLocation();
    // Check if there is a concert to edit
    const concertToEdit = location.state ? location.state.concert : null;
    // Set the state for the concerts and the new concert
    const [concerts, setConcerts] = useState([]);
    const [newConcert, setNewConcert] = useState(concertToEdit || { band: '', date: '', time: '', description: '', url: '', status: '' });
    const [isEditing, setIsEditing] = useState(!!concertToEdit);

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

        useEffect(() => {
        const verifyAuth = async () => {
            const isAuth = await checkUser();
            if (!isAuth) {
                window.location.href = '/';
            } else {
                setIsAuthenticated(true);
                setLoading(false);
            }
        };
        verifyAuth();
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewConcert((prev) => ({ ...prev, [name]: value }));
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        const url = isEditing
            ? `https://www.api.kilofon.dk/api/updateKoncert/${newConcert._id}`
            : 'https://www.api.kilofon.dk/api/addKoncert';
        const method = isEditing ? 'put' : 'post';

        axios[method](
            url,
            {
                band: newConcert.band,
                date: newConcert.date,
                time: newConcert.time,
                description: newConcert.description,
                url: newConcert.url,
                status: newConcert.status
            },
            {
                withCredentials: true
            }
        )
        .then((response) => {
            setNewConcert({ band: '', date: '', time: '', description: '', url: '', status: '' });
        })
        .catch((error) => {
            console.error('There was an error submitting the concert!', error);
        });
        //redirect to adminPanel
        window.location.href = '/adminPanel';

    };

    if (loading) {
        return displayLoading();
    }

    return (
        <Container maxWidth="md">
            <LeftSidePanel />
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" align="center" gutterBottom>
                    {isEditing ? 'Edit Concert' : 'Kommende Koncerter'}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="band-label">Band</InputLabel>
                        <Select
                            labelId="band-label"
                            name="band"
                            value={newConcert.band}
                            onChange={handleChange}
                            label="Band"
                            required
                        >
                            <MenuItem value="Halberg & Friends">Halberg & Friends</MenuItem>
                            <MenuItem value="Powertrio">Powertrio</MenuItem>
                            <MenuItem value="Santa Cruz">Santa Cruz</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Date"
                        name="date"
                        value={newConcert.date}
                        onChange={handleChange}
                        margin="normal"
                        required
                    />
                    <Typography variant="h8" component="div" sx={{ flexGrow: 1 }}>
                        Skriv med fomatet: dd-mm-yyyy (fx. 06-07-2024)
                    </Typography>
                    <TextField
                        fullWidth
                        label="Time"
                        name="time"
                        value={newConcert.time}
                        onChange={handleChange}
                        margin="normal"
                        required
                    />
                    <Typography variant="h8" component="div" sx={{ flexGrow: 1 }}>
                        Skriv med fomatet: hh:mm (fx. 19:00)
                    </Typography>
                    <TextField
                        fullWidth
                        label="Description"
                        name="description"
                        value={newConcert.description}
                        onChange={handleChange}
                        margin="normal"
                        required
                    />
                    <Typography variant="h8" component="div" sx={{ flexGrow: 1 }}>
                        Skriv en kort beskrivelse af koncerten
                    </Typography>
                    <Typography variant="h8" component="div" sx={{ flexGrow: 1 }}>
                        fx. Danmark Dejligst, Karrebæksminde
                    </Typography>
                    <TextField
                        fullWidth
                        label="URL"
                        name="url"
                        value={newConcert.url}
                        onChange={handleChange}
                        margin="normal"
                    />
                    <Typography variant="h8" component="div" sx={{ flexGrow: 1 }}>
                        Angiv en URL til koncerten hvis muligt
                    </Typography>

        <Box sx={{ display: 'flex'  }}>

                    <FormControl component="fieldset" margin="normal">
                        <FormLabel component="legend">Status</FormLabel>
                        <RadioGroup
                            name="status"
                            value={newConcert.status}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="fri entre" control={<Radio />} label="Fri entré" />
                            <FormControlLabel value="køb billet" control={<Radio />} label="Køb billet" />
                            <FormControlLabel value="udsolgt" control={<Radio />} label="Udsolgt" />
                            <FormControlLabel value="aflyst" control={<Radio />} label="Aflyst" />
                        </RadioGroup>
                    </FormControl>
        </Box>

                    <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                        {isEditing ? 'Update Concert' : 'Add Concert'}
                    </Button>
                </form>
            </Box>
        </Container>
    );
}

export default KommendeKoncerter;

