import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Grid, Button, CssBaseline, Toolbar, ListItem, ListItemText, Link, List } from '@mui/material';
import LeftSidePanel from './leftSidePalen';
import checkUser from './checkUser';
import displayLoading from './logding';
import axios from 'axios';
import TrafficStatistics from './TrafficStatistics';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function AdminPanel() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [concerts, setConcerts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const concertsPerPage = 5;
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        const verifyAuth = async () => {
            const isAuth = await checkUser();
            if (!isAuth) {
                window.location.href = '/';
            } else {
                setIsAuthenticated(true);
                setLoading(false);
                getKoncert();
            }
        };
        verifyAuth();
    }, []);

    const getKoncert = useCallback(async () => {
        try {
            const response = await axios.get('https://www.api.kilofon.dk/api/getKoncert', { withCredentials: true });
            setConcerts(response.data.koncert);
        } catch (error) {
            console.error('There was an error fetching the concerts!', error);
        }
    }, []);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(concerts.length / concertsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleEditConcert = (concert) => {
        navigate('/adminPanel/kommendeKoncerter', { state: { concert } }); // Pass concert data to edit page
    };

    if (loading) {
        return displayLoading();
    }

    const indexOfLastConcert = currentPage * concertsPerPage;
    const indexOfFirstConcert = indexOfLastConcert - concertsPerPage;
    const currentConcerts = concerts.slice(indexOfFirstConcert, indexOfLastConcert);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <LeftSidePanel />
            <Box component="main" sx={{ flexGrow: 1, p: 5 }}>
                <Typography variant="h4" align="center" gutterBottom>
                    Admin Panel
                </Typography>
                <Toolbar />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box p={2} sx={{ width: '100%', height: '100%', backgroundColor: "lightgray", borderRadius: 2 }}>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Kommende koncerter:
                            </Typography>
                            <List>
                                {currentConcerts.map((concert, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={`${concert.date} at ${concert.time}, ${concert.band}`}
                                            secondary={
                                                <>
                                                    <Typography component="span" variant="body2" color="text.primary">
                                                        {concert.description}
                                                    </Typography>
                                                    <Typography sx={{ ml: 5 }}
                                                component="span" variant="body2" color="text.primary">
                                                       {concert.status}
                                                    </Typography>
                                                    <br />
                                                    <Link href={concert.url} target="_blank" rel="noopener">
                                                    {concert.url}
                                                    </Link>
                                                </>
                                            }
                                        />
                                        <Button variant="contained" sx = {{ backgroundColor: 'orange', marginLeft: 2}} 
                                    onClick={() => handleEditConcert(concert)}>
                                            Edit
                                        </Button>
                                    </ListItem>
                                ))}
                            </List>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                <Button variant="contained" color="primary" onClick={handlePrevPage} disabled={currentPage === 1} >
                                    Previous
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleNextPage} disabled={currentPage === Math.ceil(concerts.length / concertsPerPage)}>
                                    Next
                                </Button>
                            </Box>
                            <Button variant="contained" color="primary" sx={{ mt: 2 }} href="/adminPanel/kommendeKoncerter">
                                Tilføj ny koncert
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TrafficStatistics />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default AdminPanel;

