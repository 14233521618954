import { TextField, Button, Container, Typography, Box } from '@mui/material';

function displayLoading() {
        return (
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '90vh',
                    }}
                >
                    <Typography variant="h4">Loading...</Typography>
                </Box>
            </Container>
        );

}

export default displayLoading;
